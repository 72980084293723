<template>
  <div class="form">
    <!-- 组1 -->
    <div class="weui-cells">
      <template v-for="(item, index) in nucleicAcidList">
        <!-- <div class="weui-cells__title">检测记录{{ index + 1 }}</div> -->
        <adate
          text="检测时间"
          :model.sync="item.time"
          value=""
          placeholder="请选择"
          :required="true"
        ></adate>
        <ainput
          type="text"
          text="检测地点"
          :model.sync="item.address"
          value=""
          placeholder="如：北京第二人民医院"
        ></ainput>
        <apicker
          text="核酸检测结果"
          :options="results"
          :model.sync="item.result"
          :required="true"
        ></apicker>
      </template>
    </div>
    <div class="btn-area">
      <button class="weui-btn weui-btn_primary" @click="submit">确认</button>
    </div>
    <!-- 历史记录 -->
    <section class="historyBox" v-if="!isEdit">
      <p class="trigger" @click="trigger('n')">核酸检测记录</p>
      <el-timeline class="timeline">
        <el-timeline-item
          v-for="(item, index) in nList"
          :key="index"
          :timestamp="item.time.substr(0, 10)"
          style="position: relative"
        >
          <div
            class="editBox"
            style="display: flex; justify-content: space-between"
          >
            <!-- <span class="edit" @click="edit(item)">
              <i class="el-icon-edit-outline"></i>
            </span>
            <span class="del" @click="del(item)">
              <i class="el-icon-delete"></i>
            </span> -->
          </div>
          <p style="width: calc(100% - 0.7rem)">检测地：{{ item.address }}</p>
          <p>检测结果：{{ !item.result ? "阴性" : "阳性" }}</p>
        </el-timeline-item>
      </el-timeline>
      <p class="viewAllBtn" @click="goHistory" v-if="!isEdit">点击查看全部</p>
    </section>
  </div>
</template>

<script>
import ainput from "@/components/form/input";
import apicker from "@/components/form/picker";
import adate from "@/components/form/datepicker";
import aradio from "@/components/form/radio";
import acell from "@/components/form/cell";
import aswitch from "@/components/form/switch";
import webview from "@/mixins/webview";
import {
  VaccinesType,
  EpidemicSave,
  nucleicList,
  vaccinesList,
  vaccineDelete,
  vaccineEdit,
} from "@/api/api";
import time from "@/mixins/time";
export default {
  data() {
    return {
      id: "",
      name: "",
      results: [
        {
          label: "阳性",
          value: 1,
        },
        {
          label: "阴性",
          value: 0,
        },
      ],
      types: [],
      vaccinesList: [], //接种疫苗记录提交
      nucleicAcidList: [
        {
          time: this.nowTime(),
          typeId: "",
          result: 0,
        },
      ], //核酸检测记录提交
      nList: [], //核算历史记录
      vList: [], //疫苗历史记录
      form: {
        mobile: this.$store.state.userInfo.mobile,
      },
      nflag: false,
      vflag: false,
      isEdit: false,
    };
  },
  components: {
    ainput,
    apicker,
    adate,
    aradio,
    acell,
    aswitch,
  },
  created() {
    this.isEdit = Boolean(this.$route.query.isEdit);
    document.title = this.isEdit?'编辑核酸记录':"核酸检测"
    this.$route.query.data &&
      (this.nucleicAcidList = [JSON.parse(this.$route.query.data)]);
    this.id = this.$route.query.id;
  },
  mounted() {
    this.isWebView();
    this.getVaccinesType();
    this.getNucList();
    this.getVacList();
  },
  mixins: [time, webview],
  methods: {
    goHistory() {
      let mobile = this.$store.state.userInfo.mobile;
      this.$router.push("/detailList/" + mobile + "?type=nuclear");
    },
    submit() {
      let form = {
        ...this.form,
        ...{
          isNucleicAcid: this.nucleicAcidList.length ? 1 : 0,
          nucleicAcidList: this.nucleicAcidList,
          vaccinesList: this.vaccinesList,
        },
      };
      form.isClose = this.form.isClose ? 1 : 0;
      form.isVaccines = this.form.isVaccines ? 1 : 0;
      form.isSubClose = this.form.isSubClose ? 1 : 0;
      if (this.id) {
        vaccineEdit(this.id, form.nucleicAcidList[0]).then((res) => {
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      } else {
        EpidemicSave(form).then((res) => {
          this.$weui.toast("提交成功", {
            duration: 1500,
            className: "custom-classname",
          });
          if (window.__wxjs_environment === "miniprogram") {
            setTimeout(() => {
              wx.miniProgram.navigateBack();
            }, 2000);
          } else {
            this.$router.go(-1);
          }
        });
      }
    },
    addtest() {
      this.nucleicAcidList.push({
        time: this.nowTime(),
        typeId: "",
      });
    },
    multest() {
      if (this.nucleicAcidList.length > 0) {
        this.nucleicAcidList.pop();
      }
    },
    addtimelist() {
      this.vaccinesList.push({
        time: this.nowTime(),
        address: "",
      });
    },
    multimelist() {
      if (this.vaccinesList.length > 0) {
        this.vaccinesList.pop();
      }
    },
    // 获取疫苗类型
    async getVaccinesType() {
      let res = await VaccinesType();
      let arr = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.types = arr;
    },
    // 获取核酸记录
    async getNucList() {
      let res = await nucleicList({
        mobile: this.$store.state.userInfo.mobile,
      });
      this.nList = res.data.data.slice(0, 3);
    },
    // 获取疫苗记录
    async getVacList() {
      let res = await vaccinesList({
        mobile: this.$store.state.userInfo.mobile,
      });
      this.vList = res.data.data;
    },
    // 记录收起展开
    trigger(type) {
      if (type === "n") {
        this.nflag = !this.nflag;
      } else {
        this.vflag = !this.vflag;
      }
    },
    del(item) {
      this.$weui.confirm("确认删除检测记录？", {
        title: "提示",
        buttons: [
          {
            label: "取消",
            type: "default",
            onClick: function () {},
          },
          {
            label: "确定",
            type: "primary",
            onClick: () => {
              vaccineDelete(item.id).then((res) => {
                this.$weui.toast("删除成功", {
                  duration: 1500,
                  className: "custom-classname",
                });
                this.getNucList();
              });
            },
          },
        ],
      });
    },
    edit(item) {
      this.nucleicAcidList = [
        {
          time: item.time,
          address: item.address,
          result: item.result,
        },
      ];
      this.id = item.id;
    },
  },
};
</script>

<style scoped>
.addBtn {
  color: #2f7dcd;
}
.iconground i {
  margin-left: 0.2rem;
}
.historyBox {
  padding: 0.1rem 0.2rem 0rem;
}
.trigger {
  margin: 0.1rem 0;
  color: #2f7dcd;
  font-size: 0.14rem;
  /* text-align: right; */
}
.timeline {
  overflow: hidden;
}

.expend {
  height: auto;
}
.editBox {
  position: absolute;
  right: 0;
  top: 0.1rem;
  width: 0.6rem;
}
.editBox span {
  font-size: 0.2rem;
}
.edit {
  color: #2f7dcd;
}
.del {
  color: red;
}
.btn-area {
  padding-bottom: 0.1rem;
}
</style>